import React from 'react'
import { Avatar, Badge } from 'react-daisyui'

export type ChannelListItemProps = {
  title: string
  description: string
  thumbnailUrl: string
  url: string
  badgeContent?: React.ReactNode
}

function ChannelListItem({
  title,
  description,
  thumbnailUrl,
  url,
  badgeContent
}: ChannelListItemProps) {
  return (
    <li className="card bg-base-200 duration-200">
      <a
        className="cursor-pointer rounded-box duration-200 space-y-1 p-4 group hover:bg-base-300 hover:scale-[1.02]"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex items-center gap-2 flex-wrap">
          <Avatar
            className="duration-200 drop-shadow-sm object-cover group-hover:rotate-12 delay-100"
            src={thumbnailUrl}
            size={20}
          />
          <span className="font-bold mr-auto">{title}</span>

          {badgeContent && <Badge>{badgeContent}</Badge>}
        </div>

        <p className="text-sm text-base-content/80">{description}</p>
      </a>
    </li>
  )
}

export default ChannelListItem

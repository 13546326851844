import React from 'react'
import App from 'src/App'
import Redirect from './Redirect'

// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserRouter } from 'react-router-dom'

import * as redirectMap from './redirectMap'

export const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '',
        element: <App />
      },

      ...redirectMap.root.map(({ path, to }) => ({
        path,
        element: <Redirect to={to} />
      }))
    ]
  },
  {
    path: '/shopee/product/',
    children: redirectMap.shopeeProducts.map(({ path, to }) => ({
      path,
      element: <Redirect to={to} />
    }))
  },

  // support old path
  {
    path: '/product/swi-kai-boj',
    element: <Redirect to="https://shopee.ph/keebzilla/14588778941/" />
  }
])

type RedirectPath = {
  path: string
  to: string
}

export const root: RedirectPath[] = [
  {
    path: 'shopee',
    to: 'https://shope.ee/5UzJYxmlPU'
  },
  {
    path: 'tiktok',
    to: 'https://tiktok.com/@keebzilla'
  },
  {
    path: 'facebook',
    to: 'https://facebook.com/keebzilla'
  },
  {
    path: 'instagram',
    to: 'https://instagram.com/keebzilla_'
  },
  {
    path: 'youtube',
    to: 'https://youtube.com/@keebzilla'
  },
  {
    path: 'unbox',
    to: 'https://goo.gl/maps/F74wrmRVMjPfp1tL9'
  },
  {
    path: 'sound-tests',
    to: 'https://docs.google.com/spreadsheets/d/1T_ev8mAIt4zJ5YpfRQOsl83wiz6LURdxmTUXw1xsBIY/edit#gid=0'
  },
  {
    path: 'price-list',
    to: 'https://docs.google.com/spreadsheets/d/1zBI4FzFZXgSvQrxkbcO7tyhhZE0wU5MXDYcH043j4j8/edit?usp=sharing'
  }
]

export const shopeeProducts: RedirectPath[] = [
  {
    path: 'gateron-milky-yellow-pro',
    to: 'https://shopee.ph/keebzilla/13134360056/'
  },
  {
    path: 'gateron-yellow-smd-pro',
    to: 'https://shopee.ph/keebzilla/10635725348/'
  },
  {
    path: 'gateron-milky-yellow',
    to: 'https://shopee.ph/keebzilla/10605315372/'
  },
  {
    path: 'gateron-yellow-smd',
    to: 'https://shopee.ph/keebzilla/13813388321/'
  },
  {
    path: 'gateron-yellow-ks3',
    to: 'https://shopee.ph/keebzilla/15986040725'
  },
  {
    path: 'gateron-oil-king',
    to: 'https://shopee.ph/keebzilla/12690760261/'
  },
  {
    path: 'fantasy-virus',
    to: 'https://shopee.ph/keebzilla/12491927415/'
  },
  {
    path: 'ktt-sea-salt-lemon',
    to: 'https://shopee.ph/keebzilla/12541695614/'
  },
  {
    path: 'ktt-peach',
    to: 'https://shopee.ph/keebzilla/13941674990/'
  },
  {
    path: 'ktt-kang-white',
    to: 'https://shopee.ph/keebzilla/12034414270/'
  },
  {
    path: 'ktt-mint',
    to: 'https://shopee.ph/keebzilla/11231876183/'
  },
  {
    path: 'ktt-red-wine',
    to: 'https://shopee.ph/keebzilla/11315622514/'
  },
  {
    path: 'ktt-rose',
    to: 'https://shopee.ph/keebzilla/12435578523/'
  },
  {
    path: 'ktt-pine',
    to: 'https://shopee.ph/keebzilla/19948475685'
  },
  {
    path: 'ktt-strawberry',
    to: 'https://shopee.ph/keebzilla/15053390434'
  },
  {
    path: 'ktt-matcha',
    to: 'https://shopee.ph/keebzilla/15463543912/'
  },
  {
    path: 'cherry-mx-black-hyperglide',
    to: 'https://shopee.ph/keebzilla/15382895417/'
  },
  {
    path: 'everglide-aqua-king-v3',
    to: 'https://shopee.ph/keebzilla/16808646440'
  },
  {
    path: 'jwk-black',
    to: 'https://shopee.ph/keebzilla/19212073038/'
  },
  {
    path: 'jwk-ultimate-black',
    to: 'https://shopee.ph/keebzilla/14145618572'
  },
  {
    path: 'jwk-obsidian-v2',
    to: 'https://shopee.ph/keebzilla/11310965563/'
  },
  {
    path: 'next-blue-star',
    to: 'https://shopee.ph/keebzilla/20520204139'
  },
  {
    path: 'mmd-holy-panda',
    to: 'https://shopee.ph/keebzilla/12540307665/'
  },
  {
    path: 'mmd-clear',
    to: 'https://shopee.ph/keebzilla/13052674088/'
  },
  {
    path: 'mmd-cream',
    to: 'https://shopee.ph/keebzilla/19600125275/'
  },
  {
    path: 'mmd-princess-linear',
    to: 'https://shopee.ph/keebzilla/23246056777/'
  },
  {
    path: 'mmd-princess-tactile',
    to: 'https://shopee.ph/keebzilla/22771548251/'
  },
  {
    path: 'kailh-box-jade',
    to: 'https://shopee.ph/keebzilla/14588778941/'
  },
  {
    path: 'kailh-box-navy',
    to: 'https://shopee.ph/keebzilla/18454625990/'
  },
  {
    path: 'leobog-graywood-v3',
    to: 'https://shopee.ph/keebzilla/12596627957/'
  },
  {
    path: 'aflion-ink',
    to: 'https://shopee.ph/keebzilla/22227569209/'
  },
  {
    path: 'outemu-silent-white',
    to: 'https://shopee.ph/keebzilla/2974474380/'
  },
  {
    path: 'outemu-silent-honey-peach-v2',
    to: 'https://shopee.ph/keebzilla/25211316158/'
  },
  {
    path: 'outemu-silent-gray',
    to: 'https://shopee.ph/keebzilla/4893439282/'
  },
  {
    path: 'outemu-red',
    to: 'https://shopee.ph/keebzilla/8145344980/'
  },
  {
    path: 'outemu-brown',
    to: 'https://shopee.ph/keebzilla/9673565056/'
  },
  {
    path: 'outemu-black',
    to: 'https://shopee.ph/keebzilla/4793423233/'
  },
  {
    path: 'outemu-blue',
    to: 'https://shopee.ph/keebzilla/7193437897/'
  },
  {
    path: 'akko-v3-cream-yellow-pro',
    to: 'https://shopee.ph/keebzilla/19594494370/'
  },
  {
    path: 'feker-white-marble',
    to: 'https://shopee.ph/keebzilla/25863296658/'
  }
]

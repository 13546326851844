import React from 'react'
import { Avatar, Divider } from 'react-daisyui'
import ChannelListItem, {
  ChannelListItemProps
} from './components/ChannelListItem/ChannelListItem'
import SocialListItem, {
  SocialListItemProps
} from './components/SocialListItem/SocialListItem'
import { TikTokEmbed } from 'react-social-media-embed'

import shopeeIcon from 'src/assets/images/icon-shopee.png'
import tiktokIcon from 'src/assets/images/icon-tiktok.png'
import facebookIcon from 'src/assets/images/icon-facebook.png'
import unboxIcon from 'src/assets/images/icon-unbox.png'
import soundTestsIcon from 'src/assets/images/icon-sound-tests.png'

const channels: ChannelListItemProps[] = [
  {
    title: 'Shopee',
    description:
      'Explore and purchase our wide range of mechanical keyboard products on Shopee.',
    thumbnailUrl: shopeeIcon,
    url: '/shopee',
    badgeContent: '7.4k followers'
  },
  {
    title: 'TikTok',
    description:
      'Discover trends, get inspired, and conveniently shop our products directly from our TikTok page.',
    thumbnailUrl: tiktokIcon,
    url: '/tiktok',
    badgeContent: '8.9k followers'
  },
  {
    title: 'Facebook',
    description:
      'Be the first to know about new product releases, promotions, and exciting updates from our store.',
    thumbnailUrl: facebookIcon,
    url: '/facebook',
    badgeContent: '2.8k followers'
  },
  {
    title: 'Unbox Cebu',
    description:
      'Explore our physical showcase for a hands-on experience of our mechanical keyboard products. See, feel, and purchase with confidence.',
    thumbnailUrl: unboxIcon,
    url: '/unbox'
  },
  {
    title: 'Sound Tests',
    description:
      'Explore our curated list of sound tests, showcasing the acoustic profiles of all available switches in our store. ',
    thumbnailUrl: soundTestsIcon,
    url: '/sound-tests'
  }
]

const socials: SocialListItemProps[] = [
  {
    url: '/facebook',
    icon: (
      <svg
        className="w-6 h-6"
        width="24"
        height="24"
        fill="none"
        stroke="#ffffff"
        strokeLinejoin="round"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18 6.3h-3.006a.984.984 0 0 0-.984.983v3.45H18l-.584 3.8H14.01V21.5H9.623v-6.968H6v-3.798h3.576l.047-3.606-.007-.654A3.934 3.934 0 0 1 13.549 2.5H18v3.8Z" />
      </svg>
    )
  },
  {
    url: '/instagram',
    icon: (
      <svg
        className="w-6 h-6"
        width="24"
        height="24"
        fill="none"
        stroke="#ffffff"
        strokeLinejoin="round"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 3H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4V7a4 4 0 0 0-4-4Z" />
        <path d="M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
        <path
          fill="#ffffff"
          stroke="none"
          d="M17.5 7.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        />
      </svg>
    )
  },
  {
    url: '/tiktok',
    icon: (
      <svg
        className="w-6 h-6"
        width="24"
        height="24"
        fill="none"
        stroke="#ffffff"
        strokeLinejoin="round"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.68 9.57c-2.945-.142-4.992.908-6.14 3.15-1.724 3.362-.3 8.864 5.45 8.864 5.749 0 5.915-5.556 5.915-6.138V8.938c1.23.779 2.267 1.247 3.11 1.406.844.159 1.38.23 1.61.211V7.317c-.78-.094-1.456-.273-2.025-.538-.854-.397-2.548-1.498-2.548-3.113V2.417h-3.559c-.01 7.908-.01 12.251 0 13.03.016 1.166-.89 2.799-2.725 2.799-1.836 0-2.741-1.632-2.741-2.684a2.8 2.8 0 0 1 1.136-2.269c.542-.41 1.295-.573 2.516-.573V9.57Z" />
      </svg>
    )
  },
  {
    url: '/youtube',
    // icon by IconPark
    icon: (
      <svg
        width="24"
        height="24"
        fill="none"
        stroke="#ffffff"
        strokeLinejoin="round"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.532 5.207C8.303 5.07 10.459 5 11.999 5s3.696.07 6.47.207a3 3 0 0 1 2.84 2.747c.127 1.517.19 2.853.19 4.008 0 1.17-.065 2.524-.195 4.064a3 3 0 0 1-2.794 2.741c-2.37.155-4.54.233-6.51.233-1.97 0-4.14-.078-6.51-.233a3 3 0 0 1-2.793-2.74 48.889 48.889 0 0 1-.197-4.065c0-1.145.064-2.482.193-4.01a3 3 0 0 1 2.84-2.745Z" />
        <path d="M10.5 9.806v4.398a.429.429 0 0 0 .665.357l3.299-2.18a.429.429 0 0 0 .002-.713L11.168 9.45a.429.429 0 0 0-.668.356Z" />
      </svg>
    )
  }
]

function Home() {
  return (
    <div className="flex-1">
      <section>
        <div className="flex flex-col gap-5 items-center">
          <Avatar
            src="https://down-aka-ph.img.susercontent.com/1bc319de2474feded45f956a1d8aab92_tn"
            border
            shape="circle"
          />

          <div className="flex flex-col text-center">
            <h1 className="font-bold text-lg">Keebzilla</h1>

            <h2 className="font-medium text-sm text-base-content/80">
              Your Gateway to Mechanical Keyboard Bliss
            </h2>

            <h2 className="font-medium text-sm text-base-content/80 mt-1">
              📍 Cebu City, PH
            </h2>
          </div>
        </div>
      </section>

      <Divider />

      <section>
        <div className="flex justify-center mb-6">
          <TikTokEmbed
            url="https://www.tiktok.com/@keebzilla/video/7242882825136000262"
            width={325}
          />
        </div>

        <ul className="space-y-4">
          {channels.map((channel) => (
            <ChannelListItem {...channel} />
          ))}
        </ul>
      </section>

      <Divider />

      <ul className="flex justify-center items-center gap-2 md:gap-4 flex-wrap">
        {socials.map((social) => (
          <SocialListItem {...social} />
        ))}
      </ul>
    </div>
  )
}

export default Home

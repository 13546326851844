import React from 'react'

type RedirectProps = {
  to: string
}

function Redirect({ to }: RedirectProps) {
  React.useEffect(() => {
    window.location.href = to
  }, [to])

  return null
}

export default Redirect

import React from 'react'
import Home from './features/public/Home'

function App() {
  return (
    <main className="h-screen">
      <div className="relative p-6 md:p-8 min-h-screen pb-24">
        <div className="flex max-w-xl mx-auto">
          <Home />
        </div>
      </div>
    </main>
  )
}

export default App

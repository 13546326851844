import React from 'react'

export type SocialListItemProps = {
  url: string
  icon: React.ReactNode
}

function SocialListItem({ url, icon }: SocialListItemProps) {
  return (
    <li>
      <a
        className="btn btn-square btn-ghost group"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <span className="transform duration-200 group-hover:scale-110 fill-base-content">
          {icon}
        </span>
      </a>
    </li>
  )
}

export default SocialListItem
